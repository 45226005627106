@import 'StyleVariables';

.maze_container {
  display: flex;
  flex-direction: column;

  .players {
    position: absolute;
    left: 0;
    top: 0;
  }

  .arrow_container {
    text-align: right;
    padding-right: 10px;
  }

  .maze_game {
    background-color: $white;
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    align-self: flex-end;
  }

  .pulse_container {
    display: none;
  }
}
