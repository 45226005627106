@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Regular.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Regular.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Regular.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Regular.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Bold.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Bold.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Bold.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Bold.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Italic.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Italic.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Italic.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Italic.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Italic.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Bold_Italic.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Bold_Italic.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Bold_Italic.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Bold_Italic.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Bold_Italic.svg') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Medium.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Medium.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Medium.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Medium.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Medium_Italic.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Medium_Italic.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Medium_Italic.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Medium_Italic.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Medium_Italic.svg') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Maax Akili';
  src: url('../../resources/fonts/Maax_Akili-Light.ttf') format('ttf'),
    url('../../resources/fonts/Maax_Akili-Light.eot') format('eot'),
    url('../../resources/fonts/Maax_Akili-Light.woff') format('woff'),
    url('../../resources/fonts/Maax_Akili-Light.woff2') format('woff2'),
    url('../../resources/fonts/Maax_Akili-Light.svg') format('svg');
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: 'Oduda';
  src: url('../../resources/fonts/Oduda-Bold.eot') format('eot'),
    url('../../resources/fonts/Oduda-Bold.woff') format('woff'),
    url('../../resources/fonts/Oduda-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
