@import 'StyleVariables';

.transfer_buttons_container {
  .transfer_login_button_container,
  .transfer_password_reset_button_container  {
    display: flex;
    padding-top: 18px;
    justify-content: center;
  }

  .transfer_akili_assist_button_container, .transfer_otc_support_button_container {
    display: flex;
    padding-top: 70px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    line-height: 30px;

    .nounderline {
      text-decoration: none;
    } 
  }

  .transfer_password_reset_button_container {
    .secondary_link {
      padding-bottom: 30px;
      font-size: 20px;
      line-height: 30px;
      color: $brand-v1;

      &.brand_v2 {
        color: $brand-v2;
      }
    }
  }

  .transfer_akili_assist_button_container {
    .header {
      font-weight: 700;
    }
    button {
      font-weight: 700;
      margin-top: 10px;
      color: $brand-v1;

      &:hover {
        color: $brand-v1;
      }
    
      &.brand_v2 {
        color: $brand-v2;

        &:hover {
          color: $brand-v2;
        }
      }
    }
}
  
  .transfer_otc_support_button_text_container {
    margin-top: 15px;
    
    .contact {
      font-weight: 700;
      color: $base-text;
    }
  }
}