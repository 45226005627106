@mixin setBackgroundForAnimation(
  $imageURL,
  $imageSize: 100%,
  $imagePosition: center,
  $width: 100%,
  $height: 100%,
  $position: fixed
) {
  position: relative;

  &:before {
    content: '';
    position: $position;
    height: $width;
    width: $height;
    background-image: url($imageURL);
    background-repeat: no-repeat;
    background-size: $imageSize;
    background-position: $imagePosition;
  }
}

// ------------------- //

@mixin createAnimation($values...) {
  -webkit-animation: $values;
  -ms-animation: $values;
  -moz-animation: $values;
  -o-animation: $values;
  animation: $values;
}

$bottom-left-corner: 'bottom-left-corner';
$bottom-right-corner: 'bottom-right-corner';
$top-left-corner: 'top-left-corner';
$top-right-corner: 'top-right-corner';

@mixin slideFromCorner(
  $cornerValue: $bottom-left-corner,
  $xValue: 0px,
  $yValue: 0px
) {
  &:before {
    @if $cornerValue == $bottom-left-corner {
      left: $xValue;
      bottom: $yValue;
      @include createAnimation(bottomLeftCornerSlide 0.5s forwards);
    }

    @if $cornerValue == $top-right-corner {
      right: $xValue;
      top: $yValue;
      @include createAnimation(topRightCornerSlide 0.5s forwards);
    }
  }
}

// ------------------- //

$rotation_animation_time: 240s;
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@-ms-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@-o-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@mixin backgroundRotationAnimation() {
  overflow: hidden;

  &.rotation_animation {
    &:before {
      // @include createAnimation(completeRotation $rotation_animation_time infinite linear);
      -moz-animation:spin $rotation_animation_time linear infinite;
      -webkit-animation:spin $rotation_animation_time linear infinite;
      -ms-animation:spin $rotation_animation_time linear infinite;
      -o-animation:spin $rotation_animation_time linear infinite;
      animation:spin $rotation_animation_time linear infinite;
    }
  }
}

// ------------------- //

@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

$minMediumRange: 37.5em;
$maxMediumWidth: 56.25em;
$minMediumLandscapeRange: 48.063em;
$minLargeRange: 76.25em;
$maxLargeWidth: 76.25em;

%page-container {
  @include clearfix;
  position: relative;

  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (min-width: $minMediumRange) {
    max-width: $maxMediumWidth;
  }

  @media (min-width: $minLargeRange) {
    max-width: $maxLargeWidth;
  }
}

// ------------------- //

$smallTotalColumnsCount: 4;
$mediumTotalColumnsCount: 8;
$largeTotalColumnsCount: 12;

@mixin getSizeWithTablet($largeSize, $landscapeSize, $portraitSize, $smallSize: 4) {
  @if $smallSize == 0 {
    display: none;
  } @else {
    display: flex;
    width: percentage($smallSize/$smallTotalColumnsCount);
  }

  @media (min-width: $minMediumRange) {
    @if $portraitSize == 0 {
      display: none;
    } @else {
      display: flex;
      width: percentage($portraitSize/$mediumTotalColumnsCount);
    }
  }

  @media (min-width: $minMediumLandscapeRange) {
    @if $landscapeSize == 0 {
      display: none;
    } @else {
      display: flex;
      width: percentage($landscapeSize/$mediumTotalColumnsCount);
    }
  }

  @media (min-width: $minLargeRange) {
    @if $largeSize == 0 {
      display: none;
    } @else {
      display: flex;
      width: percentage($largeSize/$largeTotalColumnsCount);
    }
  }
}