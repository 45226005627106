@import 'StyleVariables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .body {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      @extend %body-font;
      margin-top: 20px;
      text-align: center;

      .linkButton {
        margin-left: 5px;
      }

      span.success {
        @extend %body-2-font;
      }
    }
  }

  .airplaneImageContainer {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .airplane {
      width: 102px;
      height: 57px;
      position: absolute;
    }

    .airplaneBackground {
      width: inherit;
      height: inherit;
    }
  }

  .errorContainer {
    margin-bottom: 30px;
  }

  .message {
    margin-top: 30px;
  }
}
