@import 'StyleVariables';

.secondaryButton {
  @extend %primary-colors;
  cursor: pointer;
  font-weight: 700;
  border-color: $brand-v1;
  border-radius: 74px;
  background: none;
  border: none;
  color: $brand-v1;
  font-size: 16px;
  font-family: 'Maax Akili';
  padding-left: 0;

  &:focus {
    cursor: default;
    outline: none;
  }

  .buttonContentLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }

  .loadingIcon {
    width: 28px;
    height: 28px;
    background-image: url('./assets/BlueSpinner.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
