$gridColumnWidth: 150px;
$gridGutterWidth: 24px;

$minExtraSmallRange: 360px;
$maxExtraSmallRange: 767px;

$minSmallRange: 768px;
$maxSmallRange: 1023px;

$minMediumRange: 1024px;
$maxMediumRange: 1439px;

$minLargeRange: 1440px;
$maxLargeRange: 1919px;

$minExtraLargeRange: 1920px;
$maxExtraLargeRange: 3000px;

$extraSmallTotalColumnsCount: 2;
$smallTotalColumnsCount: 4;
$mediumTotalColumnsCount: 5;
$largeTotalColumnsCount: 7;
$extraLargeTotalColumnsCount: 8;

@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

%page-container {
  @include clearfix;
  @extend %full-height-page;
  @extend %full-width;

  @media (min-width: $minSmallRange) {
    max-width: $maxSmallRange;
  }

  @media (min-width: $minMediumRange) {
    max-width: $maxMediumRange;
  }

  @media (min-width: $minLargeRange) {
    max-width: $maxLargeRange;
  }

  @media (min-width: $minExtraLargeRange) {
    max-width: $maxExtraLargeRange;
  }
}

%full-height-page {
  position: relative;
}

%full-width {
  width: 100%;
}

@mixin getContentSize($extraSmallColumnCount, $smallColumnCount, $mediumColumnCount, $largeColumnCount, $extraLargeColumnCount) {
  width: ($gridColumnWidth * $extraSmallColumnCount) + ($gridGutterWidth * ($extraSmallColumnCount - 1));

  @media (min-width: $minSmallRange) {
    width: ($gridColumnWidth * $smallColumnCount) + ($gridGutterWidth * ($smallColumnCount - 1));
  }

  @media (min-width: $minMediumRange) {
    width: ($gridColumnWidth * $mediumColumnCount) + ($gridGutterWidth * ($mediumColumnCount - 1));
  }

  @media (min-width: $minLargeRange) {
    width: ($gridColumnWidth * $largeColumnCount) + ($gridGutterWidth * ($largeColumnCount - 1));
  }

  @media (min-width: $minExtraLargeRange) {
    width: ($gridColumnWidth * $extraLargeColumnCount) + ($gridGutterWidth * ($extraLargeColumnCount - 1));
  }
}
