@import 'StyleVariables';

.content_divider {
  .separator {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: $grey-10;
  }
}
