@import 'StyleVariables';

.container {
  flex-grow: 1;

  .form {
    .section {
      @extend %body-2-font;
      width: 100%;
      margin-top: 20px;

      .highlight {
        font-style: italic;
      }
    }
    .question {
      @extend %body-font;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.animated_disabled {
  position: relative;
  top: 10%;
  opacity: 0;
  animation: slide 0.25s forwards;
}

.separator {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 2px;
  border-radius: 2px;
  background: $grey-10;
}

.noSeparator {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 2px;
}

.errorContainer {
  margin-top: 30px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

@keyframes slide {
  100% {
    top: 0;
    opacity: 1;
  }
}
