@import 'StyleVariables';

.input_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border: 2px solid rgba(($brand-v1), 0.4);  
  border-radius: 6px;
  padding: 20px 40px 20px 20px;
  @extend %body-font-input;

  &:focus, &:global(.active) {
    border: 2px solid $brand-v1;
  }
}

.slash {
  display: flex;
  margin-right: 2px;
  margin-left: 2px;
  font-weight: 700;
}

.input {
  display: flex;
  @extend %body-font-input;
  font-family: 'Maax Akili' !important;
  outline: none !important;
  border: 0 !important;
  padding: 0 !important;
}

.date, .month {
 width: 20px !important;
}

.year {
 width: 40px !important;
}

:global(.field.error) {
  .input_container {
    border-color: $error-red !important;
  }
}
