@import 'StyleVariables';

.container {
  display: flex;
  flex-grow: 1;

  .errorContainer {
    margin-top: 30px;
  }

  .spinnerContainer {
    margin-top: 40%;
  }
}
