@import 'StyleVariables';

.spinner {
  width: 32px;
  height: 32px;
  background-image: url('./assets/BlueSpinner.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;
}

.fullScreen {
  display: flex;
  flex-grow: 1;
}