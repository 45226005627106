@import 'StyleVariables';

.text_input {
  input::-webkit-input-placeholder {
      color: $input-placeholder !important;
  }
  input::-moz-placeholder {
      color: $input-placeholder !important;
  }
  input::-ms-input-placeholder {
      color: $input-placeholder !important;
  }

  label {
    font-weight: 700;
  }

  .error_message {
    margin-top: 10px;
    color: $error-red;
  }

  :global(>.ui.input > input[type=text]),
  :global(>.ui.input > input[type=email]),
  :global(>.ui.input > input[type=password]),
  :global(>.ui.input > input[type=number]),
  :global(>.ui.input > input[type=date]) {
    font-family: 'Maax Akili' !important;
    border: 2px solid rgba(($brand-v1), 0.4);
    border-radius: 6px;
    padding: 20px 40px 20px 20px;
    @extend %body-font-input;

    &:focus {
      border: 2px solid rgba(($brand-v1), 1);
    }

    &.brand_v2 {
      border: 2px solid rgba(($brand-v2), 0.4);

      &:focus {
        border: 2px solid rgba(($brand-v2), 1);
      }
    }
  }

  &:global(.error) {
    input::-webkit-input-placeholder {
        color: $input-placeholder !important;
    }
    input::-moz-placeholder {
        color: $input-placeholder !important;
    }
    input::-ms-input-placeholder {
        color: $input-placeholder !important;
    }

    label {
      color: $error_red !important;
      font-weight: 700;

      .label_error_info {
        // font-weight: 400;
      }
    }

    :global(.input),
    :global(>.ui.input input[type=text]),
    :global(>.ui.input input[type=number]),
    :global(>.ui.input input[type=email]),
    :global(>.ui.input input[type=password]),
    :global(>.ui.input input[type=date]) {
      border-color: $error-red !important;
      background: $white !important;
      color: $base-text !important;
    }
  }

  :global(.fa.fa-warning) {
    position: absolute;
    top: 24px;
    right: 22px;
    pointer-events: none;
    font-size: 16px;
    color: $error-red;
  }
}

:global(#zipcode-container) {
  @media (min-width: $minSmallRange) {
    max-width: 50%;
  }
}
