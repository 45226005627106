%display-font {
  font-size: 34px;
  line-height: 51px;
}

%headline-font {
  font-size: 24px;
  line-height: 35px;
}

%title-font {
  font-size: 20px;
  line-height: 30px;
}

%title-2-font {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

%subheader-font {
  font-size: 18px;
  line-height: 27px;
}

%body-font {
  font-size: 16px;
  line-height: 24px;
}

%body-2-font {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

%body-font-input {
  font-size: 16px !important;
  line-height: 19px !important;
}

%caption-font {
  font-size: 12px;
  line-height: 16px;
}

%button-font {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

/** colors */
%dark-primary {
  color: $base-text;
}

%dark-secondary {
  color: rgba(0, 0, 0, 0.6);
}

%dark-disabled {
  color: rgba(0, 0, 0, 0.4);
}

%light-primary {
  color: $white;
}

%light-secondary {
  color: rgba(255, 255, 255, 0.7);
}

%brand-primary {
  color: $brand-v1;
}

%brand-success {
  color: $success-green;
}

%brand-error {
  color: $error-red;
}

%light-disabled {
  color: rgba(255, 255, 255, 0.5);
}

%game_font_family {
  font-family: 'Oduda', 'Maax Akili' !important;
}
