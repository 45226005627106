.video_responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 20px;
  margin: 36px 0;
  display: none;

  &.show {
   display: block;
  }

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 20px;
  }
}

.placeholderImgContainer {
  display: block;

  &.hide {
    display: none;
  }

  .placeholderImg {
    width: 100%;
    cursor: point;
  }
}
