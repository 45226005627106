@import 'StyleVariables';

.footer {
  background: $white;
  padding: 25px 0;
  bottom: 0;
  width: 100%;

  .content {
    @include getContentSize(2, 3, 3, 3, 3);
    @extend %caption-font;
    text-align: center;

    a {
      text-decoration: underline;
      color: $brand-v1;

      &.brand_v2 {
        color: $brand-v2;
      }
    }

    a:hover {
      color: $link-color-hover;
    }
  }

 &.light {
    background: transparent;
    color: $white;

  .content {
    a {
      color: $white;
    }

    a:hover {
      color: $blue-5;
    }
  }
 }

 &.overlayContent {
    position: absolute;
    bottom: 0;

    .content {
      width: 324px;
      margin: 0 auto 0 auto;
      text-align: center;

      @media (min-width: $minSmallRange) {
        width: 100%;
      }
    }
 }
}
