@import 'StyleVariables';

.loading_state {
  width: 28px;
  height: 28px;
  background-image: url('./assets/WhiteSpinner.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;

  &.is_fab_button {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
  &.is_secondary {
    background-image: url('./assets/BlueSpinner.gif');
  }
}

:global(.ui.button) {
  &.floating_button {
    background: $brand-v1;
    color: $white;

    @extend %subheader-font;
    @include componentShadow(1px, 1px);
    text-transform: capitalize;
    cursor: pointer;
    height: 72px;
    max-height: 72px;
    width: 72px;
    max-width: 72px;
    padding: 24px;
    margin: 0px;
    border-radius: 50%;
    position: relative;

    &:hover {
      background: $brand-v1;
      @include componentShadow(8px, 4px, $blue-shadow-50);
    }

    &:disabled {
      @extend %dark-disabled;
      background: $grey-20;
    }

    &:active {
      background-color: $blue-2;
      color: $grey-5;
    }

    &.brand_v2 {
      background: $brand-v2;

      &:hover {
        background: $brand-v2;
      }
    }
  }
}
  