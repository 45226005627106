@import 'StyleVariables';

.linkButton:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.linkButton:disabled {
  cursor: default;
  outline: none;
  color: $base-text;
  text-decoration: none;
}
.linkButton:focus {
  outline: none;
}
.linkButton:hover:not(:disabled) {
  color: $link-color-hover;
  text-decoration: underline;
}
.linkButton {
  font-weight: 400;
  color: $brand-v1;
  text-decoration: none;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: unset;
  border: none;
  border-radius: none;
  text-transform: none;
  outline: none;
  overflow: none;
  margin: auto;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  min-width: 40px;
  text-decoration: underline;
  padding: 0px;
}

.loading_state {
  width: 28px;
  height: 28px;
  background-image: url('./assets/BlueSpinner.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: -20px;
  position: absolute;
}
