@import 'StyleVariables';

.checkbox_container {
  display: flex;
  flex-direction: row;
  margin-left: -12px;
  padding: 12px 0 12px 12px;

  &:focus {
    outline:0 !important;
  }

  .checkbox_component {
    background: $white;
    width: 18px;
    height: 18px;
    min-width: 18px;
    border-radius: 2px;
    border: 2px solid rgba(($brand-v1), 0.4);
    cursor: pointer;
    margin-top: 3px;

    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
    user-select:none;

    &:focus {
      border-color: $brand-v1;
    }
    &.checked {
      background: $brand-v1;
      border-color: $brand-v1;
    }
  }

  .label {
    margin-left: 15px;
  }
}

:global(.error).checkbox_container {
  .checkbox_component {
    border-color: $error-red;
  }
}

.error_message {
  margin-top: 10px;
  color: $error-red;
  float: left;
}
