@import 'StyleVariables';

:global(.ui.form) {
  >:global(.fields) {
    margin-bottom: 30px;

    >:global(.field) {
      &:not(:first-of-type) {
        margin-top: 30px !important;
      }

      >:global(label) {
        margin-bottom: 20px;
        @extend %body-font-input;
        font-weight: 700;
      }

      .error_message {
        margin-top: 10px;
        color: $error-red;
      }

      :global(.ui.selection.dropdown) {
        @extend %body-font-input;
        border: 2px solid rgba(($brand-v1), 0.4);        
        border-radius: 6px;
        padding: 20px;


        &:global(.active) {
          border: 2px solid $brand-v1;
          border-radius: 6px !important;


          &:not(:global(.upward)) {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;

            >:global(.menu) {
              margin: 2px -2px 0 -2px;
            }

          }

          &:global(.upward) {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;

            >:global(.menu) {
              margin: 0 -2px 2px -2px;
              border-top-width: 2px!important;
              border-radius: 6px 6px 0 0!important;
            }
          }
        }

        >:global(.dropdown.icon) {
          padding: 20px;
          color: $brand-v1;
        }

        >:global(.menu) {
          min-width: calc(100% + 4px);
          width: calc(100% + 4px);
          max-height: 208px;

          border: 2px solid $brand-v1;

          &:global(.visible) {

            >:global(.item) {
              margin: 8px;
              border-radius: 6px;
              padding: 16px 15px !important;

              &:hover {
                background: $blue-9;
              }
              &:global(.selected) {
                background: $brand-v1;
                color: $white;
                font-weight: unset;
              }
            }
          }
        }
      }

      &:global(.error) {
        >:global(label) {
          color: $error-red;
        }

        :global(.ui.selection.dropdown) {
          border-color: $error-red !important;
          background: $white;
          color: $base-text;

          :global(.divider.default.text) {
            color: $input-placeholder;
          }

          :global(.text) {
            background: $white;
            color: $base-text;
          }

          >:global(.dropdown.icon) {
            color: $error-red;
          }
          >:global(.menu) {
            border-color: $error-red;

            &:global(.visible) {

              >:global(.item) {
                background-color: $white;
                &:hover {
                  background-color: $blue-9;
                }
                &:global(.selected) {
                  background-color: $brand-v1 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $minSmallRange) {
  :global(.ui.form) {
    >:global(.fields) {
      >:global(.field) {
        &:not(:first-of-type) {
          margin-top: 0 !important;
        }
      }
    }
  }
}
