@import 'StyleVariables';
.container {
    flex-grow: 1;
    .errorContainer {
        margin-top: 30px;
    }
    .form {
        margin-top: 30px;
    }
    .buttonContainer {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;

        button {
            width: auto;
            min-width: 150px;
        }
    }
    .bold {
        font-weight: bold;
    }
    a {
        text-decoration: underline;
        color: $brand-v1;
    }

    a:hover {
        color: $link-color-hover;
    }
}
