//sub brand colors

$sub-mdd: #e6d028;
$sub-ms: #7a315a;

// greyscale
$white: #ffffff;
$grey-5: #f6f6f7;
$grey-6: #F2F2F2; // new
$grey-10: #e6e7e8;
$grey-20: #d0d2d3;
$grey-40: #a6a8ab;
$grey-60: #808184;
$grey-80: #58595b;
$black: #000000;

// bluescale
$blue-9: #f4f6f7;
$blue-8: #f9fbff;
$blue-7: #f3f6fc;
$blue-6: #d6e8ff;
$blue-5: #bcd6ff;
$blue-4: #96b2ff;
$blue-3: #829eff;
$blue-2: #4757f4;
$blue-1: #2F80ED; // new
$dark-blue: #11205d;
$blue-shadow: rgba(162, 169, 179, 0.25);
$blue-shadow-50: rgba(162, 169, 179, 0.5);

$brand-v1: rgb(17, 64, 233); // #1140e9;
$brand-v2: rgb(0, 166, 115); // #00A673;

$sub-adhd-10: rgba(131, 234, 209, 0.1);
$sub-adhd-25: rgba(131, 234, 209, 0.25);
$sub-adhd-40: rgba(131, 234, 209, 0.4);
$sub-adhd-60: rgba(131, 234, 209, 0.6);
$sub-adhd-80: rgba(131, 234, 209, 0.8);
$sub-adhd: #83ead1;

$success-green: #008059;
$steps-green: #00a673;
$success-green-10: #e8faef;
$error-red: #ff1744;
$error-red-10: #ffecec;

$caution-orange: #ff9100;
$snackbar-background: #2c2c2c;

%primary-colors {
  background: $brand-v1;
  color: $white;
}

%secondary-colors {
  background: $white;
  color: $brand-v1;
}

// Reporting Dashboard
$endeavor-green: #60e7b0;
$endeavor-mint-light: #d2fae4;
$endeavor-mint-light-2: #73d8a5;
$endeavor-mint-dark: #3b6f55;
$endeavor-orange: #f19737;
$blue-light: #e8ebfb;
$grey-light: #999998;
$grey-dark: #666666;
$transparent: 'transparent';
$assist-blue-info: #eef1fd;

$base-text: #4F4F4F;
$input-placeholder: #979797;
$link-color: #1e70bf;
$link-color-hover: #0056b3;
