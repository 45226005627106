@import 'StyleVariables';

.check_group_container {
  @extend %body-font;


  .error_message {
    color: $error-red;
  }
}
