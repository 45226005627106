@import 'StyleVariables';

.header {
  width: 100%;
  .title {
    @extend %title-2-font;
    width: 100%;
    margin-top: 40px;
  }

  .description {
    @extend %body-font;
    width: 100%;
    margin-top: 20px;

    a {
      color: $link-color-hover;
      text-decoration: underline;

      &.brand_v2 {
        color: $brand-v2;
      }
    }
  }

  .separator {
    margin-top: 30px;
  }
}
