@import 'StyleVariables';

.container {
  width: 100%;
  background-color: $base-text;
  border-radius: 6px;
  padding: 20px;

  .content {
    @extend %caption-font;
    text-align: center;
    display: flex;

    .messageContainer {
      margin-left: 20px;
      color: $white;
      @extend %body-font;
      text-align: left;
    }
  }
}