@import 'StyleVariables';

.loadingContainer {
  flex-grow: 1;
  display: flex;
}

.container {
  flex-grow: 1;

  .body {
    margin: 30px 0;

    .content_divider {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .stepsContainer {
      .stepsTitle {
        color: $steps-green;
        @extend %caption-font;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .stepsInfo {
        @extend %body-2-font;
        margin-bottom: 24px;
      }
    }

    .body_text {
      margin: 30px 0;

      .link {
        color: $base-text;
        text-decoration: underline;
      }
    }
  }

  .media_container_default {
    margin: 40px 0 30px 0;
  }

  .media_container_variant {
    margin: 24px 0 30px 0;
  }

  .logo_container {
    display: flex;
    flex-direction: row;

    .logo {
      align-items: flex-start;
      margin-right: 15px;
    }

    .appstores_details {
      display: flex;
      flex-direction: column;
    }
  }

  .icons {
    :global(#ios-store-link) {
      margin-right: 38px;
    }

    .store_icons {
      :global(a) {
        display: inline-block;
      }
    }
  }

  .logos_inline_container {
    display: none;
    margin-top: 0px;

    @media (min-width: $minSmallRange) {
      margin-top: 12px;
      display: inline-block;
    }
  }

  .logos_separate_container {
    margin-top: 28px;
    display: block;

    @media (min-width: $minSmallRange) {
      margin-top: 0px;
      display: none;
    }
  }

  .bold {
    font-weight: bold;
  }

  .title {
    @extend %body-2-font;
  }
}
