@import './colors.scss';

@mixin componentShadow(
  $blurRadius,
  $spreadRadius: 2px,
  $shadow-color: $blue-shadow
) {
  -webkit-box-shadow: 0 0 $blurRadius $spreadRadius $shadow-color;
  -moz-box-shadow: 0 0 $blurRadius $spreadRadius $shadow-color;
  box-shadow: 0 0 $blurRadius $spreadRadius $shadow-color;

  @media print {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}