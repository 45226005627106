@import 'StyleVariables';

.container {
  display: flex;
  @include getContentSize(2, 3, 3, 3, 3);

  .body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;

    .header {
      text-align: center;
      text-transform: unset;
      padding: 10px 0;
      @extend %title-font;
    }

    .header, .subheader {
      white-space: pre-wrap;
    }

    .message {
      @extend %body-font;
      @extend %dark-secondary;
      text-align: center;
      text-transform: unset;
      padding-bottom: 16px;
    }
    .icon_container {
      display: flex;
      justify-content: center;
      padding: 10px 0 40px;
    }

    .errorDetails {
      text-align: left;
      background-color: $grey-6;
      border-radius: 10px;
      padding: 16px;
      @extend %caption-font;

      .title {
        font-weight: bold;
        margin-bottom: 12px;
      }

      .code {
        margin-bottom: 6px;
      }
    }
    .success {
      text-align: left;
      .description {
        margin-top: 56px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 38px;
        white-space:pre-wrap;
      }
    }    
    
    .failContentContainer {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      flex-direction: column;

      .header, .subheader, .contact  {
        font-size: 18px;
        line-height: 30px;
      }
      .subheader {
        margin-top: 18px;
      }
      .contact {
        font-weight: 700;
      }
    }    
  }
}
