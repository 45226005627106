@import 'StyleVariables';

.container {
  flex-grow: 1;

  .errorContainer {
    margin-top: 30px;
  }

  .form {
    margin-top: 30px;

    .separator {
      margin-bottom: 27px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
  
  .success {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
  }
}