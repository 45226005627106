@import 'StyleVariables';

.container {
  display: flex;
  @include getContentSize(2, 3, 3, 3, 3);

  .body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;

    .header, .subheader {
      white-space: pre-wrap;
    }

    .errorDetails {
      text-align: left;
      background-color: $grey-6;
      border-radius: 10px;
      padding: 16px;
      @extend %caption-font;
      margin-top: 100px;

      .title {
        font-weight: bold;
        margin-bottom: 12px;
      }

      .code {
        margin-bottom: 6px;
      }
    }
  }

  .icon_container {
    display: flex;
    justify-content: center;
    padding: 10px 0 35px;
  }

  .failContentContainer {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: column;

    .header, .subheader, .contact  {
      font-size: 20px;
      line-height: 30px;
    }
    .subheader {
      margin-top: 20px;
    }
    .contact {
      font-weight: 700;
    }    
  }    
}