@import 'StyleVariables';
// @import 'Styles/variables/_grid-item.scss';
// @import 'StyleAnimations';
@import './mixins.scss';

.not_found_page {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 700px;
  background-color: $brand-v1;

  @include setBackgroundForAnimation(
    '../../views/RouteNotFound/assets/WhiteTrans.svg',
    unset,
    center,
    200%,
    200%
  );

  @include slideFromCorner(top-right-corner, -100%, -50%);

  @include backgroundRotationAnimation();

  .page_container {
    @extend %page-container;
    color: $white;
    display: flex;
    flex-direction: row;
    height: 100%;

    .left_margin {
      @include getSizeWithTablet(1, 2, 2, 0);
    }

    .page_content {
      @include getSizeWithTablet(10, 4, 4, 4);
      min-height: 100%;
      max-height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;

      .game_container {
        display: none;
        flex-grow: 1;
        margin-top: -100px;
      }

      .text_container {
        margin: auto;

        .header {
          font-size: 160px;
          line-height: 170px;
          font-weight: bold;
          margin-bottom: 40px;
        }

        .subheader {
          font-size: 34px;
          line-height: 51px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        .home_button_container {
          display: flex;

          .icon_container {
            padding-top: 8px
          }

          .return_home_link {
            color: $white;
            @extend %button-font;
          }
        }
      }
    }

    .right_margin {
      @include getSizeWithTablet(1, 2, 2, 0);
    }
  }
}

@media (min-width: $minMediumRange) {
  .not_found_page {
    @include setBackgroundForAnimation(
      '../../views/RouteNotFound/assets/WhiteTrans.svg',
      unset,
      center,
      120%,
      120%
    );

    @include slideFromCorner(top-right-corner, -10%, -10%);

    .page_container {
      .page_content {
        .text_container {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: $minLargeRange) {
  .not_found_page {
    @include setBackgroundForAnimation(
      '../../views/RouteNotFound/assets/WhiteTrans.svg',
      unset,
      center,
      140%,
      140%
    );

    @include slideFromCorner(top-right-corner, -80%, -20%);
  }

  .not_found_page {
    .page_container {
      .page_content {
        .text_container {
          padding: 0 50px;
        }

        .game_container {
          display: block;
        }
      }
    }
  }
}
