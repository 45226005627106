@import 'StyleVariables';
.container {
    flex-grow: 1;
    .errorContainer {
        margin-top: 30px;
    }
    .form {
        margin-top: 30px;
    }
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
    }
    .bold {
        font-weight: bold;
    }
}