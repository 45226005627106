@import 'StyleVariables';

.radio_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -12px !important;
  padding: 12px 0 12px 12px;

  &:focus {
    outline: 0 !important;
  }

  .radio_component {
    background: $white;
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 11px;
    border: 2px solid rgba(($brand-v1), 0.4);    
    cursor: pointer;

    &:focus {
      border-color: $brand-v1;
    }
    &.checked {
      background: $white;
      border-color: $brand-v1;
    }
  }

  .label {
    margin-left: 15px;
  }

  .bullet {
    margin-left: 3px;
    margin-top: 3px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: $brand-v1;
  }
}

:global(.error).radio_container {
  .radio_component {
    border-color: $error-red;

    &:focus, &:focus-visible {
      border-color: $error-red !important;
    }
  }
}
