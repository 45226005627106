@import 'StyleVariables';
%primary-colors {
  background: $white;
  color: $brand-v1;
}

.loginButton:focus {
  outline: none;
}

.loginButton {
  @extend %primary-colors;
  cursor: pointer;
  font-weight: bold;
  color: $brand-v1;
  text-decoration: none;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: unset;
  border: none;
  border-radius: none;
  text-transform: none;
  outline: none;
  overflow: none;
  margin-right: auto;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  min-width: 40px;
  padding: 0px;
  position: relative;
}

.captionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  div:first-child {
    padding-top: 5px;
  }
  div {
    display: block;
  }
}