@import 'StyleVariables';

.page_layout {
  display: flex;
  @extend %page-container;
  min-height: 100%;

  .page_container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;

    .side {
      display: flex;
      flex-direction: column;

      &.left_side {
        background-color: $brand-v1;

        background-image: url("./assets/left_bg.png");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: top;

        display: none;
        align-content: flex-end;
        padding-top: 60px;

        &.brand_v2 {
          background-color: $brand-v2;
        }

        .left_content {
          display: flex;
          height: 100%;
          @include getContentSize(0, 1, 1, 3, 4);
        }
      }

      &.right_side {
        background-color: $white;
        justify-content: center;
        padding-top: 60px;

        .right_content {
          display: flex;
          padding-bottom: 55px;
          height: 100%;
          @include getContentSize(2, 3, 3, 3, 3);
        }
      }
    }
  }
}

@media (min-width: $minSmallRange) {
  .page_layout {
    .page_container {
      .side {
        &.left_side {
          display: block;
          flex-grow: 1;

          .left_content {
            margin-left: auto;
          }
        }

        &.right_side {
          padding-left: $gridGutterWidth;
          flex-grow: 1;
          justify-content: flex-start;

          .right_content {
            margin-right: auto;
          }
        }
      }
    }
  }
}

@media (min-width: $minMediumRange) {
  .page_layout {
    .page_container {
      .side {
        &.left_side {
          padding-right: $gridGutterWidth;
        }

        &.right_side {
          padding-left: $gridColumnWidth + $gridGutterWidth;
        }
      }
    }
  }
}

@media (max-width: $minExtraSmallRange) {
  .page_layout {
    .page_container {
      .side {
        &.left_side {
          display: none;
        }

        &.right_side {
          padding-top: 40px;
          justify-content: flex-start;

          .right_content {
            margin-right: auto;
          }
        }
      }
    }
  }
}
